@charset "utf-8";
/* CSS Document */


@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
#menu li:nth-child(1) img{ display:none;}
#menu li:nth-child(5) img{ display:none;}
#menu{ width:90%; display:block; margin:0 auto;}
}
@media screen and (max-width: 756px) {
#menu_01{ display:none;}
}
@media screen and (max-width: 330px) {
}



/* +++++++++++++++++++++++++++++++++++++++ */
/* +++メガメニュー++++++++++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++++++++ */
#menu_01{
	width:100%;
/*	background:url(../01_img/header/mega/bg_01.jpg);*/
}

#menu_01 li.logo{
	margin:-93px 0 0 0;
}



/*ここからMega Drop Down Menuの設定*/
#menu {
 list-style: none;
 margin: 0px auto 0px auto;
 height: 60px;
 padding: 0px;
}
#menu li {
 float: left;
 display: block;
 text-align: center;
 position: relative;
}
#menu li a {
 font-family: Arial, Helvetica, sans-serif;
 display: block;
 outline: 0;
 height:100%;
 text-decoration: none;
}
#menu li:hover a {
	color:#333;
	text-decoration:none;
	line-height:1;
}
#menu .area_01 li a:hover {
	color:#333;
	text-decoration:none;
	filter:alpha(style=0, opacity=70);
	-moz-opacity:0.7;
	opacity:0.7;
}



.menu_01, .menu_02, .menu_03, .menu_04, .menu_05, .menu_06, .menu_07,.menu_02 .menu_02_1 {
 margin: 4px auto;
 float: left;
 position: absolute;
 left: -999em; /* Hides the drop down */
 text-align: left;
/* padding: 10px 5px 10px 5px;*/
}
/* ++++++++++++++++++++++++++++++++++++ */
/* ++++++++++++++++++++++++++++++++++++ */
/* ++++++++++++++++++++++++++++++++++++ */
a:hover.gnavi_00 img,
a:hover.gnavi_01 img,
a:hover.gnavi_02 img,
a:hover.gnavi_03 img,
a:hover.gnavi_04 img,
a:hover.gnavi_05 img,
a:hover.gnavi_06 img,
a:hover.gnavi_07 img{
	filter:alpha(style=0, opacity=7);
	-moz-opacity:0.7;
	opacity:0.7;

}
a:hover.gnavi_00,
a:hover.gnavi_01,
a:hover.gnavi_02,
a:hover.gnavi_03,
a:hover.gnavi_04,
a:hover.gnavi_05,
a:hover.gnavi_06,
a:hover.gnavi_07{
	filter:alpha(style=0, opacity=100)!important;
	-moz-opacity:1!important;
	opacity:1!important;
}


.inner_02 {
	position:absolute;
	margin:-2px 0 0 0;
	z-index:99999;
	background:#fff;
}
.inner_02 h2{
	color:#fff;
	text-align:center;
	font-weight:bold;
	padding:10px 0;
	margin:0 0 10px 0;
}

.area_01 li,
.area_02 li,
.area_03 li,
.area_04 li,
.area_05 li,
.area_06 li,
.area_07 li{
	font-size:14px;
	line-height:30px;
}


/* +++ menu_01 ++++++++++++ */

.menu_01 {
	width:100%;
	margin:0;
}
.menu_01 .inner_02 {
	top:0px;
	left:-30px;
	z-index:999;
	padding:0;
}
.menu_01 .inner_02_2 {
	background:#f5f5f5;
}
.menu_01 .area_01{
	width:250px;
	margin:0;
	padding:0;
	color:#333;
	box-shadow: 2px 2px 4px -2px gray ;
}
.menu_01 .area_01 ul{
	width:100%;
	margin:0;
}

.menu_01 .area_01 li{
	font-family:Arial, Helvetica, sans-serif;
	width:100%;
	display:block;
	margin:0 auto;
}
.menu_01 .area_01 li a{
	display:block;
	padding:10px 0px;
	display:block;
}
.menu_01 .area_01 li a:hover{
	background:#ececec;
}




/* +++ menu_02 ++++++++++++ */

.menu_02 {
	width:100%;
	margin:0;
}
.menu_02 .inner_02 {
	top:0px;
	left:-10px;
	z-index:999;
	padding:0;
}
.menu_02 .inner_02_2 {
	background:#f5f5f5;
}
.menu_02 .area_01{
	width:250px;
	margin:0;
	padding:0;
	color:#333;
	box-shadow: 2px 2px 4px -2px gray ;
}
.menu_02 .area_01 ul{
	width:100%;
	margin:0;
}

.menu_02 .area_01 li{
	font-family:Arial, Helvetica, sans-serif;
	width:100%;
	display:block;
	margin:0 auto;
}
.menu_02 .area_01 li a{
	display:block;
	padding:10px 0px;
	display:block;
}
.menu_02 .area_01 li a:hover{
	background:#ececec;
}


.menu_02 .area_01 li a{
	display:block;
	padding:20px 0px;
}
.menu_02 .area_01 li p{
	display:block;
	padding:20px 0px;
}

.menu_02 .area_01 li .menu_02_1 a:hover{
	background:#f5f5f5!important;
}



/* +++ menu_03 ++++++++++++ */

.menu_03 {
	width:100%;
	margin:0;
}
.menu_03 .inner_02 {
	top:0px;
	left:-35px;
	z-index:999;
	padding:0;
}
.menu_03 .inner_02_2 {
	background:#f5f5f5;
}
.menu_03 .area_01{
	width:250px;
	margin:0;
	padding:0;
	color:#333;
	box-shadow: 2px 2px 4px -2px gray ;
}
.menu_03 .area_01 ul{
	width:100%;
	margin:0;
}

.menu_03 .area_01 li{
	font-family:Arial, Helvetica, sans-serif;
	width:100%;
	display:block;
	margin:0 auto;
}
.menu_03 .area_01 li a{
	display:block;
	padding:10px 0px;
	display:block;
}
.menu_03 .area_01 li a:hover{
	background:#ececec;
}












/* +++++++++++++++++++++++++++++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++++++++++++ */
#menu li:hover .menu_01, #menu li:hover .menu_02, #menu li:hover .menu_03, #menu li:hover .menu_04, #menu li:hover .menu_05, #menu li:hover .menu_06, #menu li:hover .menu_07{
 left: -1px;
 top: 63px;
}

#menu li:hover .menu_02 li:hover .menu_02_1{
	top:-4px;	left: 250px;
	width:300px;
	background:#fff;
 
}

#menu .menu_right {
 float: right;
 margin-right: 0px;
}

/* +++++++++++++++++++++++++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++++++++ */
